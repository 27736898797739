import * as React from 'react'
import loadable from '@loadable/component'

import {
  FooterThemeInterface,
  SocialIcon,
} from '@thg-commerce/gravity-patterns/Footer/theme'
import { styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

const SocialIconPlaceholder = () => {
  return <div style={{ width: 40, height: 40 }}></div>
}

const CircularFacebook = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularFacebook'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularInstagram = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularInstagram'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularLine = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularLine'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularPinterest = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularPinterest'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularSnapchat = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularSnapchat'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularTwitter = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularTwitter'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularTwentyOneButtons = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/Circular21Buttons'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularVk = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularVk'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularWhatsapp = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularWhatsapp'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularYoutube = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularYoutube'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularTwitch = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularTwitch'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const CircularTikTok = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Colour/CircularTikTok'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const GreyFacebook = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Grey/GreyFacebook'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const GreyTwitter = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Grey/GreyTwitter'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const GreyInstagram = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Grey/GreyInstagram'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const GreyPinterest = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Grey/GreyPinterest'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const GreyTikTok = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Grey/GreyTikTok'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const GreyYouTube = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Grey/GreyYouTube'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOpacityFacebook = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOpacity/WhiteOpacityFacebook'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOpacityTwitter = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOpacity/WhiteOpacityTwitter'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOpacityInstagram = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOpacity/WhiteOpacityInstagram'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOpacityYouTube = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOpacity/WhiteOpacityYouTube'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOpacityPinterest = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOpacity/WhiteOpacityPinterest'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOpacityTiktok = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOpacity/WhiteOpacityTiktok'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOutlineFacebook = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOutline/WhiteOutlineFacebook'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOutlineTwitter = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOutline/WhiteOutlineTwitter'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOutlineYoutube = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOutline/WhiteOutlineYoutube'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOutlineInstagram = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOutline/WhiteOutlineInstagram'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const WhiteOutlineTikTok = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/WhiteOutline/WhiteOutlineTikTok'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const BlackFacebook = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackFacebook'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const BlackTwitter = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackTwitter'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const BlackInstagram = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackInstagram'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const BlackTikTok = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackTikTok'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const BlackYouTube = loadable(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackYouTube'
    ),
  { fallback: <SocialIconPlaceholder /> },
)
const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const StyledConnectWithUs = styled.div`
  margin-left: ${spacing(0)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-left: ${spacing(2)};
  }
`

const TagLine = styled.div`
  padding-bottom: ${spacing(2)};
  ${(props) =>
    Text(
      props.theme.patterns.footer.customerEngagement.headerText.entry ||
        'bodyText',
      props.theme.patterns.footer.customerEngagement.headerText.style ||
        'alternate',
    )}
  text-transform: ${(props) =>
    props.theme.patterns.footer.customerEngagement.headerText.transform ||
    'none'};
  text-decoration: ${(props) =>
    props.theme.patterns.footer.customerEngagement.headerText.textDecoration ||
    'none'};
  color: ${(props) =>
    props.theme.patterns.footer.customerEngagement.headerText.textColor};
`

const CircularSocialLinks = styled.a`
  display: inline-flex;
  border: solid
    ${(props) =>
      props.theme.patterns.footer.customerEngagement.socialIconsBorder.display
        ? `${props.theme.patterns.footer.customerEngagement.socialIconsBorder.width} 
           ${props.theme.patterns.footer.customerEngagement.socialIconsBorder.color}`
        : `2px transparent`};
  height: ${(props) => (props.isColorSocialIcon ? '48px' : '44px')};
  padding: ${(props) =>
    props.isColorSocialIcon
      ? spacing(0.25)
      : props.theme.patterns.footer.customerEngagement.socialIconsPadding ||
        '0px'};
  border-radius: 25px;
  align-items: center;

  &:focus-visible {
    outline: none;
    border: 2px solid
      ${(props) =>
        props.theme.patterns.footer.customerEngagement.socialIconsBorder
          .color || props.theme.colors.palette.brand.base};
  }

  &:hover {
    ${(props) =>
      props.theme.patterns.footer.customerEngagement?.socialIconsOutline?.color
        ? `outline: 2px solid ${props.theme.patterns.footer.customerEngagement.socialIconsOutline.color};`
        : `opacity: 0.6`}
  }

  ${(props) =>
    props.theme.patterns.footer.customerEngagement.socialIconType ===
      SocialIcon.grey &&
    `svg {
      circle {
        fill: ${props.theme.patterns.footer.customerEngagement.socialIconsFill.circle};
      }
      path {
        fill: ${props.theme.patterns.footer.customerEngagement.socialIconsFill.path};
      }
    }`}
`

const InlineLi = styled.li`
  padding-right: ${(props) =>
    spacing(
      props.theme.patterns.footer.customerEngagement.socialIconsPadding ? 0 : 1,
    )};
  display: inline;
`

interface ActionProps {
  href: string
  as?: string
  children: React.ReactElement
  [x: string]: any
}

export interface ConnectWithUsProps {
  text: string
  socialAccounts: { url: string; socialNetwork: string }[]
  actionComponent: React.FunctionComponent<ActionProps>
}

const greyIcons = (
  socialIconTheme: FooterThemeInterface['customerEngagement']['socialIcon'],
) => {
  return {
    FACEBOOK: socialIconTheme.facebook.svgPath ? (
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox={socialIconTheme.viewBox}
        width={socialIconTheme.width}
        height={socialIconTheme.height}
      >
        <path d={socialIconTheme.facebook.svgPath} fillRule="evenodd" />
      </SvgIcon>
    ) : (
      <GreyFacebook />
    ),
    INSTAGRAM: <GreyInstagram />,
    YOUTUBE: <GreyYouTube />,
    TWITTER: <GreyTwitter />,
    PINTEREST: socialIconTheme.pinterest.svgPath ? (
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox={socialIconTheme.viewBox}
        width={socialIconTheme.width}
        height={socialIconTheme.height}
      >
        <path d={socialIconTheme.pinterest.svgPath} fillRule="nonzero" />
      </SvgIcon>
    ) : (
      <GreyPinterest />
    ),
    TIKTOK: <GreyTikTok />,
    WHATSAPP: socialIconTheme.whatsapp.svgPath ? (
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        width={socialIconTheme.width}
        height={socialIconTheme.height}
      >
        <path d={socialIconTheme.whatsapp.svgPath} fillRule="nonzero" />
      </SvgIcon>
    ) : (
      <GreyFacebook />
    ),
  }
}

const iconTypes: { [key: string]: SocialIcon } = {
  black: SocialIcon.black,
  colour: SocialIcon.color,
  grey: SocialIcon.grey,
  whiteOpacity: SocialIcon.whiteOpacity,
  whiteOutline: SocialIcon.whiteOutline,
}

const whiteOpacityIcon: { [key: string]: React.ReactElement } = {
  FACEBOOK: <WhiteOpacityFacebook />,
  INSTAGRAM: <WhiteOpacityInstagram />,
  YOUTUBE: <WhiteOpacityYouTube />,
  TWITTER: <WhiteOpacityTwitter />,
  PINTEREST: <WhiteOpacityPinterest />,
  TIKTOK: <WhiteOpacityTiktok />,
}

const blackIcons: { [key: string]: React.ReactElement } = {
  FACEBOOK: <BlackFacebook />,
  INSTAGRAM: <BlackInstagram />,
  YOUTUBE: <BlackYouTube />,
  TWITTER: <BlackTwitter />,
  TIKTOK: <BlackTikTok />,
}

const whiteOutlineIcon: { [key: string]: React.ReactElement } = {
  FACEBOOK: <WhiteOutlineFacebook />,
  TWITTER: <WhiteOutlineTwitter />,
  YOUTUBE: <WhiteOutlineYoutube />,
  INSTAGRAM: <WhiteOutlineInstagram />,
  TIKTOK: <WhiteOutlineTikTok />,
}

const socialIcons: { [key: string]: React.ReactElement } = {
  FACEBOOK: <CircularFacebook />,
  INSTAGRAM: <CircularInstagram />,
  LINE: <CircularLine />,
  PINTEREST: <CircularPinterest />,
  SNAPCHAT: <CircularSnapchat />,
  TIKTOK: <CircularTikTok />,
  TWENTYONE_BUTTONS: <CircularTwentyOneButtons />,
  TWITCH: <CircularTwitch />,
  TWITTER: <CircularTwitter />,
  VK: <CircularVk />,
  WHATSAPP: <CircularWhatsapp />,
  YOUTUBE: <CircularYoutube />,
}

export const ConnectWithUs = ({
  actionComponent: PassedActionComponent,
  ...props
}: ConnectWithUsProps) => {
  const theme = useTheme()

  const getSocialNetworkIcon = (socialNetwork: string, iconType: string) => {
    const socialIconTheme = theme.patterns.footer.customerEngagement.socialIcon

    if (iconType === iconTypes.grey) {
      return greyIcons(socialIconTheme)[socialNetwork] || null
    }
    if (iconType === iconTypes.whiteOpacity) {
      return whiteOpacityIcon[socialNetwork] || null
    }
    if (iconType === iconTypes.black) {
      return blackIcons[socialNetwork] || null
    }
    if (iconType === iconTypes.whiteOutline) {
      return whiteOutlineIcon[socialNetwork] || null
    }
    return socialIcons[socialNetwork] || null
  }

  const createSocialAccountLink = (index, socialAccount) => {
    const socialIcon = getSocialNetworkIcon(
      socialAccount.socialNetwork,
      theme.patterns.footer.customerEngagement.socialIconType,
    )

    if (!socialIcon) {
      return null
    }

    return (
      <InlineLi key={`social-account-circle-${index}`}>
        <PassedActionComponent href={socialAccount.url}>
          <CircularSocialLinks
            target="_blank"
            data-testid="social-account-circle"
            aria-label={socialAccount.socialNetwork.toLowerCase()}
            title={socialAccount.socialNetwork.toLowerCase()}
            isColorSocialIcon={
              theme.patterns.footer.customerEngagement.socialIconType ===
              iconTypes.colour
            }
          >
            {socialIcon}
          </CircularSocialLinks>
        </PassedActionComponent>
      </InlineLi>
    )
  }

  const SocialAccountCircles = props.socialAccounts.map((el, index) => {
    return createSocialAccountLink(index, el)
  })

  return props.socialAccounts.length > 0 ? (
    <StyledConnectWithUs>
      <TagLine id={'social-accounts'}>{props.text}</TagLine>
      <ul aria-labelledby={'social-accounts'}>{SocialAccountCircles}</ul>
    </StyledConnectWithUs>
  ) : null
}
